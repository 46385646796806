import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { InputSelect } from '../InputTypes/InputSelect/InputSelect';
import { checkEmptyFieldValue, getInputType, checkValidAttachmentField } from '../../utils';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import './formContent.css';
import { useTranslator } from '../../translation/hooks';
import { formatPayload } from './library';

export const FormContent = props => {
  const {
    fields,
    handleFormPost,
    setShowToaster,
    setToasterMessage,
    setToasterType,
    isIframe,
    phoneTypes
  } = props

  const [formPayload, setFormPayload] = useState([]);
  const [errorOnForm, setErrorOnForm] = useState(false);
  const [hasRequired, setHasRequired] = useState(false);
  const [loadingFormSend, setLoadingFormSend] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const { Translation } = useTranslator();

  const ordinatedFields = useMemo(() => [...fields].sort((a,b) => a.Ordination - b.Ordination), [fields])

  useEffect(() => {
    const formPayload = buildFormPayload(ordinatedFields);
    setFormPayload(formPayload);
  }, [ordinatedFields])

  const handleShowErrorToaster = () => {
    setShowToaster(true);
    setToasterMessage(Translation('GENERICS.TOASTER.FORM_ERROR'));
    setToasterType('ERROR');

    setTimeout(() => {
      setShowToaster(false);
    }, 5000);
  }

  const buildFormPayload = fields => {
    const tempFormPayload = [];
    let hasOneRequired = false;
    fields.forEach(field => {
      const payloadObject = {};
      payloadObject[field.ObjectKey] = '';
      payloadObject.required = field.Required;
      tempFormPayload.push(payloadObject);
      if (field.Required) hasOneRequired = true;
    });

    setHasRequired(hasOneRequired);

    return [...tempFormPayload];
  }

  const onInputChange = useCallback((value, index, key, required, isValid) => {
    const payloadItem = {};
    payloadItem[key] = value;
    payloadItem.required = required;
    if (typeof isValid === 'boolean') {
      payloadItem.isValid = isValid;
    }
    setFormPayload([
      ...formPayload.slice(0, index),
      { ...payloadItem },   
      ...formPayload.slice(index + 1),
    ])
  }, [formPayload]);

  const onClickSubmit = useCallback( async e => {
    e.preventDefault();

    setLoadingFormSend(true);
    try {
      const { data, hasErrorOnForm } = formatPayload({
        payload: formPayload,
        ordinatedFields
      });

      setErrorOnForm(hasErrorOnForm);

      if(hasErrorOnForm) return handleShowErrorToaster();
      
      await handleFormPost(data);
      setFormSent(true);
    } catch (error){
      console.log(error);
    } finally {
      setLoadingFormSend(false);
    }

  }, [formPayload, handleFormPost, ordinatedFields, handleShowErrorToaster]);

  return (
    <form className={`form-content ${isIframe ? '--zero-padding --no-container' : ''}`}>
      {ordinatedFields.map((field, index) => {
        const canShowSelect = field.Options.length > 0 || (field.Field.TypeId === 7 && field.Field.SecondaryEntityId === 25);
        const fieldValue = formPayload[index] ? formPayload[index][field.ObjectKey] : '';
        const invalidAttachments = checkValidAttachmentField(fieldValue, field);

        return (
          <div className='form-content__item' key={index}>
            <label htmlFor='name' className='form-content__label'>{field.Label}{field.Required && '*'}</label>
            <div className='form-content__desc'>{field.Description}</div>
            {errorOnForm && checkEmptyFieldValue(formPayload[index][field.ObjectKey]) && field.Required &&
              <div className='form-content__validation'>
                { Translation('FIELDS.REQUIRED_EMPTY') }
              </div>
            }
            {errorOnForm && invalidAttachments?.length > 0 &&
              <div className='form-content__validation'>{field.Label} com extensões "{invalidAttachments.toString()}" inválidas. </div>
            }
            {canShowSelect
              ? <InputSelect field={field} index={index} setValue={onInputChange} />
              : <>{getInputType(fieldValue, onInputChange, field, index, phoneTypes)}</>
            }
          </div>
        )
      })}
      <div className='form-content__item'>
        <button onClick={onClickSubmit}
          disabled={loadingFormSend || formSent}
          className={`form-content__button ${loadingFormSend || formSent ? '--disabled' : ''}`}
        >
          {loadingFormSend ? <LoadingDots /> : (formSent ? Translation('GENERICS.TOASTER.FORM_SUCCESS') : Translation('GENERICS.SEND'))}
        </button>
        {hasRequired && <p className='form-content__desc'>* { Translation('FIELDS.REQUIRED') }</p>}
      </div>
    </form>
  )
}