import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import useTranslator from '../../../translation/hooks/useTranslator';

export const InputFormattedText = props => {
  const {
    setValue,
    field,
    index,
    format,
    suffix,
    allowedDecimalSeparators,
    decimalSeparator,
    decimalScale,
    validation,
  } = props;

  const [formattedProps, setFormattedProps] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [invalidInput, setInvalidInput] = useState(false);
  const { Translation } = useTranslator();

  useEffect(() => {
    const propObject = {}
    if (format) propObject.format = format;
    if (suffix) propObject.suffix = suffix;
    if (allowedDecimalSeparators) propObject.allowedDecimalSeparators = allowedDecimalSeparators;
    if (decimalSeparator) propObject.decimalSeparator = decimalSeparator;
    if (decimalScale) propObject.decimalScale = decimalScale;
    propObject.fixedDecimalScale = !!decimalScale;
    if (field.FieldKey === 'contact_zipcode') propObject.format='#####-###';

    setFormattedProps({...propObject});
  }, [format, suffix, decimalSeparator, decimalScale]);

  useEffect(() => {
    let hasInvalidInput = false;
    if (validation) {
      hasInvalidInput = !validation(inputValue);
      setInvalidInput(hasInvalidInput);
    }
    const isValid = !hasInvalidInput;

    setValue(hasInvalidInput ? '' : inputValue.replace(suffix, ''), index, field.ObjectKey, field.Required, isValid);

  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  }

  return (
    <>
    {invalidInput && <div className='form-content__validation'>{Translation('FIELDS.INVALID_INPUT')}</div>}
    <NumberFormat
      className={`form-content__input ${invalidInput ? '--invalid' : ''}`}
      onChange={handleInputChange}
      value={inputValue}
      name={field.FieldKey}
      type='tel'
      {...formattedProps}
    />
    </>
  );
}

InputFormattedText.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  format: PropTypes.string,
  suffix: PropTypes.string,
  allowedDecimalSeparators: PropTypes.array,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  validation: PropTypes.func
}