import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { unshift } from '../../../utils/unshift';

export const InputText = props => {
  const {
    value = '',
    setValue,
    field,
    index,
  } = props;

  const [text, setText] = useState(value);

  const handleInputChange = (input) => {
    if (field.Field.TypeId === 5) {
      unshift(input.target.value, setText);
    } else {
      setText(input.target.value);
    }
  }

  useEffect(() => {
    setValue(text, index, field.ObjectKey, field.Required);
  }, [text]);

  return (
    <input
      className='form-content__input'
      onChange={handleInputChange}
      value={text}
      name={field.FieldKey}
      type='text'
      required={field.Required}
    />
  );
}

InputText.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
