import ptBR from 'date-fns/locale/pt-BR';
import ptPT from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

function getLocale(language) {
  switch(language) {
    case 'ptBR':
    default:
      return ptBR
    case 'en':
      return en
    case 'ptPT':
      return ptPT
    case 'es':
      return es
    };
}

export default getLocale;