import React from 'react';
import Interweave from 'interweave';
import './formInfo.css';

export const FormInfo = props => {
  const {
    info,
    textColor = '#333',
  } = props;
  return (
    <div className='form-info'>
      {info.avatarUrl && <img className='form-info__company-logo' src={info.avatarUrl} alt='logo'/>}
      <h2 className='form-info_form__title' style={{ color: textColor }} >{info.name}</h2>
      <div className='form-info_form__desc' style={{ color: textColor }} >
      <Interweave className='form-info_form__desc' content={info.description} />
      </div>
    </div>
  );
}