import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import './inputDateTime.css';
import { useFormInfo } from '../../../context/states/formInfo';
import useTranslator from '../../../translation/hooks/useTranslator';
import { formatDate, getLocale } from './library';

export const InputDateTime = props => {
  const {
    type,
    setValue,
    field,
    index,
  } = props;

  const [selectedDate, setSelectedDate] = useState();
  const [formInfo] = useFormInfo();
  const language = useMemo(()=> formInfo.language, [formInfo]);
  const dateFormat = useMemo(()=> {
    return language === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  }, [language]);
  const timeFormat = useMemo(()=> {
    return language === 'en' ? 'hh:mm a' : "HH:mm";
  }, [language]);
  const { Translation } = useTranslator();

  useLayoutEffect(()=>{
    registerLocale(language, getLocale(language));
  }, [language]);

  useEffect(() => {
    const date = formatDate(type, selectedDate);
    setValue(date, index, field.ObjectKey, field.Required);
  }, [selectedDate]);

  switch (type) {
    case 'date':
    default:
      return (
        <DatePicker 
          selected={selectedDate}
          locale={language}
          className="form-content__input" 
          type="date" 
          onChange={setSelectedDate} 
          dateFormat={dateFormat}
        />
      )
    case 'time':
      return (
        <DatePicker
          selected={selectedDate}
          onChange={setSelectedDate}
          locale={language}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={Translation('GENERICS.HOUR')}
          dateFormat={timeFormat}
        />
      );
  }

}

InputDateTime.propTypes = {
  type: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
