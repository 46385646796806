import React, {useEffect} from "react";
import { useTranslator } from "../../translation/hooks";

export const TitleComponent = () => {
    const { Translation } = useTranslator();

    useEffect(() => {
        const pageTitle = Translation("GENERICS.EXTERNAL_FORM_TITLE");
        document.title = pageTitle
    })
    
    return null;
};
