import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './inputUpload.css';
import useTranslator from '../../../translation/hooks/useTranslator';

export const InputUpload = props => {
  const {
    name,
    accept,
    setValue,
    field,
    index,
  } = props;

  const inputProps = { name, accept };
  const [fileTooBig, setFileTooBig] = useState([]);
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);
  const { Translation } = useTranslator();

  useEffect(() => {
    let attachmentObject = [];

    files.forEach(file => {
      attachmentObject.push({
        Attachment: {
          Content: file.value,
          ContentType: file.type,
          FileName: file.label
        }}
      );
    })

    if (attachmentObject.length === 1) {
      attachmentObject = attachmentObject[0];
    } else if (attachmentObject.length === 0) {
      attachmentObject = '';
    }

    setValue(attachmentObject, index, field.ObjectKey, field.Required);
  }, [files]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }

  const getFileTypeFromName = (file) => {
    let fileType = '';
    const fileExtension = file.name.split('.').at(-1);
    if (fileExtension === 'md') fileType = 'text/markdown';
    return fileType;
  }

  const onChange = useCallback((e) => {
    const isMultiple = field?.Field?.Multiple;
    const readFiles = [...e.target.files];
    const newFilesArray = [];
    const fileTooBigArray = [];
  
    readFiles.forEach(file => {
      if (file.size <= 5242880) {
        convertToBase64(file)
          .then(resolve => {
            const fileValueFromResolve = resolve.split(',').at(-1);
            newFilesArray.push({
              value: fileValueFromResolve,
              label: file.name,
              type: file.type || getFileTypeFromName(file),
            });
            const filesToUpload = isMultiple ? [...files, ...newFilesArray] : [...newFilesArray];
            setFiles(filesToUpload);
            e.target.value = null;
          })
      } else {
        fileTooBigArray.push(file.name);
      }
    })

    setFileTooBig(fileTooBigArray);

  }, [files, field])

  const handleSelectChange = (object) => {
    setFiles(object ? [...object] : []);
  }

  return (
    <div className='form-content__upload__wrapper'>
      {fileTooBig.length > 0 && 
        fileTooBig.map(item => (
          <div className='form-content__validation'>{Translation('FIELDS.FILE_TOO_BIG_WARN', {item: item})}</div>
        ))
      }
      <div className='form-content__desc'>{Translation('FIELDS.FILE_MAX_SIZE_ALLOWED')}</div>
      <div className='form-content__upload__inputs'>
        <Select
          placeholder=''
          isClearable={true}
          classNamePrefix='react-select'
          className='react-select-select'
          options={files}
          value={files}
          onChange={handleSelectChange}
          noOptionsMessage={() => Translation('FIELDS.INPUT_WITHOUT_OPTIONS')}
          isMulti={field.Field.Multiple}
          isSearchable={false}
          menuIsOpen={false}
        />
        <label htmlFor={field.Key} className="form-content__upload__label" >
          <input 
            ref={inputRef}
            id={field.Key}
            className={`form-content__upload ${files.length > 0 ? '--has-file' : ''}`}
            onChange={onChange}
            type="file"
            {...inputProps}
            multiple={field.Field.Multiple}
          />
          {Translation('FIELDS.CHOOSE_FILE')}
        </label>
      </div>
    </div>
  )

}

InputUpload.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}