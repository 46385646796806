/**
 * @type {{ translation: ITranslationObject }}
 */
const en = {
  translation: {
    FIELDS: {
      REQUIRED: 'required field',
      REQUIRED_EMPTY: 'Required field not filled',
      INVALID_INPUT: 'The entered value is invalid.',
      PHONE_WITHOUT_MASK: 'No mask',
      PHONE_TYPE_COMERCIAL: 'Business',
      PHONE_TYPE_CELLPHONE: 'Cellphone',
      PHONE_TYPE_RESIDENTIAL: 'Residential',
      PHONE_TYPE_FAX: 'Fax',
      PHONE_TYPE_OTHERS: 'Others',
      INPUT_WITHOUT_OPTIONS: 'No options match your search.',
      FILE_MAX_SIZE_ALLOWED: 'Maximum allowed file size: 5MB',
      FILE_TOO_BIG_WARN: 'The file {{item}} is bigger than 5MB',
      CHOOSE_FILE: 'Choose file',
    },
    GENERICS: {
      TOASTER: {
        FORM_ERROR: 'Error while submitting the form',
        FORM_SUCCESS: 'Form sent successfully',
      },
      EXTERNAL_FORM_TITLE: 'External form | Powered by Ploomes',
      SEND: 'Send',
      HOUR: 'Hour',
      FORM_NOT_FOUND: 'A form could not be found'
    },
  },
};

export default en;
