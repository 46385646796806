import React from 'react';
import './ploomesFloat.css';

export const PloomesFloat = () => {
  return (
      <a 
        className='ploomes-float'
        href='http://www.ploomes.com'
        target='_blank'
        rel='noopener noreferrer'>
        <img
          className='ploomes-float__logo'
          src='/assets/pbpr.png'
          alt='ploomes' />
          Powered by Ploomes
     </a>
  )
}