import React, { useCallback, useEffect, useState } from 'react';
import invert from 'invert-color';
import queryString from 'query-string';
import { FormContent } from './components/FormContent/FormContent';
import { FormInfo } from './components/FormInfo/FormInfo';
import { PloomesFloat } from './components/PloomesFloat/PloomesFloat';
import { Loading } from './components/Loading/Loading';
import { formGet, formPost } from './utils/service';
import { Toaster } from './components/Toaster/Toaster';
import './PloomesExternalForm.css';
import "./translation";
import { getLanguage } from './utils';
import { useTranslator } from './translation/hooks';
import { useFormInfoDispatch } from './context/states/formInfo';
import { TitleComponent } from './components/Title/Title';

const PloomesExternalForm = () => {

  const [loadingFormInfo, setLoadingFormInfo] = useState(true);
  const [canLoadForm, setCanLoadForm] = useState(false);
  const [fields, setFields] = useState([]);
  const [phoneTypes, setPhoneTypes] = useState([{ "Id": 1, "Name": "Comercial" }, { "Id": 2, "Name": "Celular" }, { "Id": 3, "Name": "Residencial" }, { "Id": 4, "Name": "Fax" }, { "Id": 5, "Name": "Outros" }]);
  const [info, setInfo] = useState({ name: '', description: '', avatarUrl: '' });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const [toasterType, setToasterType] = useState('');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [isIframe, setIsIframe] = useState(false);
  const { Translation, configTranslation } = useTranslator();
  const formInfoDispatch = useFormInfoDispatch();

  useEffect(() => {
    const queryObject = queryString.parse(window.location.search);
    setIsIframe(queryObject.iframe ? true : false);
  }, []);

  const handleFormGet = useCallback(() => {
    formGet()
      .then(response => {
        const lang = getLanguage(response.LanguageId);
        const info = {
          name: response.Name,
          description: response.Description,
          avatarUrl: response.AvatarUrl,
          languageId: response.LanguageId,
          language: lang
        };
        setInfo(info);
        formInfoDispatch(info);
        setPhoneTypes(response.PhoneTypes);
        setFields(response.Fields);
        setBgColor(response.Color ? response.Color : '#ffffff');
        return configTranslation.changeLanguage(lang);
      })
      .catch(()=>{
        const navigatorLanguage = navigator.language || navigator.userLanguage; 
        const lang = getLanguage(navigatorLanguage);
        return configTranslation.changeLanguage(lang);
      })
      .finally(() => {
        setLoadingFormInfo(false);
      });
  }, [setInfo, setFields, setLoadingFormInfo, setBgColor, configTranslation, formInfoDispatch]);

  useEffect(() => {
    if (canLoadForm)  handleFormGet();
  }, [canLoadForm, handleFormGet]);
  
  const handleFormPost = useCallback(async data => {
    return formPost(data)
    .then(() => {
      setToasterMessage(Translation('GENERICS.TOASTER.FORM_SUCCESS'));
      setToasterType('SUCCESS');
    })
    .catch(()=> {
      setToasterMessage(Translation('GENERICS.TOASTER.FORM_ERROR'));
      setToasterType('ERROR');
    })
    .finally(() => {
      setShowToaster(true);
      setTimeout(() => {
        setShowToaster(false);
      }, 5000);
    });
  }, [Translation]);

  return (
    <div className={`ploomes-external-form ${isIframe ? '--zero-padding' : ''}`} style={{backgroundColor: !isIframe ? bgColor : 'transparent'}}>
      <Loading setCanLoadForm={setCanLoadForm} active={loadingFormInfo} isIframe={isIframe} />
      {fields.length > 0 &&
        <>
          <TitleComponent />
          {!isIframe && <FormInfo info={info} textColor={invert(bgColor, {black: '#333', white: '#fff'})} />}
          <FormContent
            fields={fields}
            handleFormPost={handleFormPost}
            setToasterType={setToasterType}
            setToasterMessage={setToasterMessage}
            setShowToaster={setShowToaster}
            isIframe={isIframe}
            phoneTypes={phoneTypes}
          />
        </>
      }
      {fields.length === 0 && !loadingFormInfo &&
        <h2 className='ploomes-external-form__not-found'>{Translation('GENERICS.FORM_NOT_FOUND')}</h2>
      }
      {!isIframe && <PloomesFloat />}
      <Toaster show={showToaster} message={toasterMessage} type={toasterType} />
    </div>
  );
}

export default PloomesExternalForm;
