/**
 * @type {{ translation: ITranslationObject }}
 */
const ptPT = {
  translation: {
    FIELDS: {
      REQUIRED: 'campo obrigatório',
      REQUIRED_EMPTY: 'Campo obrigatório não preenchido',
      INVALID_INPUT: 'O valor digitado é inválido',
      PHONE_WITHOUT_MASK: 'Sem máscara',
      PHONE_TYPE_COMERCIAL: 'Comercial',
      PHONE_TYPE_CELLPHONE: 'Telemóvel',
      PHONE_TYPE_RESIDENTIAL: 'Residencial',
      PHONE_TYPE_FAX: 'Fax',
      PHONE_TYPE_OTHERS: 'Outros',
      INPUT_WITHOUT_OPTIONS: 'Não há opções referentes a sua pesquisa',
      FILE_MAX_SIZE_ALLOWED: 'Tamanho máximo permitido por arquivo: 5MB',
      FILE_TOO_BIG_WARN: 'O arquivo {{item}} possui tamanho maior que 5MB',
      CHOOSE_FILE: 'Escolher arquivo',
    },
    GENERICS: {
      TOASTER: {
        FORM_ERROR: 'Erro ao enviar o formulário',
        FORM_SUCCESS: 'Formulário enviado com sucesso'
      },
      EXTERNAL_FORM_TITLE: 'Formulário externo | Powered by Ploomes',
      SEND: 'Enviar',
      HOUR: 'Hora',
      FORM_NOT_FOUND: 'Não foi possível localizar um formulário'
    }
  }
};

export default ptPT;