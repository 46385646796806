import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getCities } from '../../../utils/service';
import useTranslator from '../../../translation/hooks/useTranslator';
import './inputSelect.css';

export const InputSelect = props => {
  const {
    index,
    field,
    setValue,
    fieldOptions,
  } = props;

  const [options, setOptions] = useState([]);
  const { Translation } = useTranslator();

  useEffect(() => {
    if (fieldOptions) return setOptions([...fieldOptions]);

    if (field) {
      const options = [];
      field.Options.forEach((option, index) => {
        options.push({
          value: (field.MappedById && option.IntegerValue) ? option.IntegerValue : option.Name,
          label: option.Name,
          ordination: option.Ordination !== null && option.Ordination !== undefined ? option.Ordination : index
        });
      });
      setOptions([...options].sort((a, b) => a.ordination - b.ordination))
    }
  }, [field, fieldOptions])

  async function loadCities(search, loadedOptions, { page }) {
    const response = await getCities(search, page);
    const responseJSON = await response.json();
    const cityOptions = [];

    responseJSON.forEach(city => {
      cityOptions.push({
        value: city.Id,
        label: `${city.Name} - ${city.State ? city.State.Short + ' - ' : ''}${city.Country.Short}`
      })
    })

    return {
      options: cityOptions,
      hasMore: cityOptions.length > 0,
      additional: {
        page: page + 1,
      },
    };
  }

  const handleSelectChange = useCallback((object) => {
    let value = [];
    if (Array.isArray(object) && object.length > 0) {
      object.forEach(item => {
        value.push(item.value);
      })
    } else {
      value = object?.value ? object.value : null;
    }

    setValue(value, index, field.ObjectKey, field.Required);
  }, [setValue, index, field]);

  const isNotCity = field.Options.length > 0 || (field.Field.TypeId !== 7 && field.Field.SecondaryEntityId !== 25);
  return (
    <>
      {isNotCity ?
        <Select
          placeholder=''
          isClearable={true}
          classNamePrefix='react-select'
          className='react-select-select'
          options={options}
          onChange={handleSelectChange}
          noOptionsMessage={() => Translation('FIELDS.INPUT_WITHOUT_OPTIONS')}
          isMulti={field.Field.Multiple}
        />
        :
        <AsyncPaginate
          placeholder=''
          isClearable={true}
          classNamePrefix='react-select'
          className='react-select-select'
          loadOptions={loadCities}
          onChange={handleSelectChange}
          noOptionsMessage={() => Translation('FIELDS.INPUT_WITHOUT_OPTIONS')}
          isMulti={field.Field.Multiple}
          additional={{
            page: 1,
          }}
        />
      }
    </>
  )
}

InputSelect.propTypes = {
  fieldOptions: PropTypes.array,
  isMulti: PropTypes.bool,
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
}
