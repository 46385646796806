import moment from "moment";

function formatDate(type, selectedDate) {
  let date = ''
  switch (type) {
    case 'date':
    default:
      if (selectedDate) {
        date = new Date(selectedDate).toISOString();
        date = moment(selectedDate).format();
        date = moment(date).format('YYYY-MM-DD');
      }
      break;
    case 'time':
      if (selectedDate) {
        date = new Date(selectedDate).toISOString();
        date = moment(selectedDate).format();
        date = '1970-01-01T' + date.substring(
          date.lastIndexOf("T") + 1,
          date.length
        )
      }
      break;
  }
  return date;
};

export default formatDate;