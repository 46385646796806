const checkEmptyFieldValue = (value) => {
  const isEmpty = (val) => val === '' || val === null || val === undefined || Number.isNaN(val);

  if (Array.isArray(value)) {
    return value.some(obj => obj.hasOwnProperty('phone') && isEmpty(obj.phone));
  }
  
  return isEmpty(value);
}

export default checkEmptyFieldValue;