const INVALID_ATTACHMENTS = [
  ".com",
  ".exe",
  ".dll",
  ".ocx",
  ".ps1",
  ".ps1xml",
  ".ps2",
  ".ps2xml",
  ".psc1",
  ".psc2",
  ".msh",
  ".msh1",
  ".msh2",
  ".mshxml",
  ".msh1xml",
  ".msh2xml",
  ".js",
  ".jse",
  ".vbs",
  ".vb",
  ".vbe",
  ".cmd",
  ".bat",
  ".hta",
  ".inf",
  ".reg",
  ".pif",
  ".scr",
  ".cpl",
  ".scf",
  ".msc",
  ".pol",
  ".hlp",
  ".chm",
  ".ws",
  ".wsf",
  ".wsc",
  ".wsh",
  ".jar",
  ".rar",
  ".z",
  ".bz2",
  ".cab",
  ".gz",
  ".tar",
  ".ace",
  ".msi",
  ".msp",
  ".mst",
  ".msu",
  ".ppkg",
  ".bak",
  ".tmp",
  ".ost",
  ".pst",
  ".pkg",
  ".iso",
  ".img",
  ".vhd",
  ".vhdx",
  ".application",
  ".lock",
  ".lck",
  ".sln",
  ".cs",
  ".csproj",
  ".resx",
  ".config",
  ".resources",
  ".pdb",
  ".manifest",
  ".mp3",
  ".wma",
  ".doc",
  ".dot",
  ".wbk",
  ".xls",
  ".xlt",
  ".xlm",
  ".xla",
  ".ppt",
  ".pot",
  ".pps",
  ".ade",
  ".adp",
  ".mdb",
  ".cdb",
  ".mda",
  ".mdn",
  ".mdt",
  ".mdf",
  ".mde",
  ".ldb",
  ".wps",
  ".xlsb",
  ".xlam",
  ".xll",
  ".xlw",
  ".ppam",
  ".c",
  ".cgi",
  ".pl",
  ".class",
  ".cpp",
  ".h",
  ".java",
  ".php",
  ".py",
  ".sh",
  ".swift",
  ".asp",
  ".aspx",
  ".cer",
  ".cfm",
  ".cgi",
  ".css",
  ".htm",
  ".html",
  ".jsp",
  ".part",
  ".rss",
  ".xhtml",
  ".apk",
  ".bin",
  ".gadget",
];

export default INVALID_ATTACHMENTS;