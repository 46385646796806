import { INVALID_ATTACHMENTS } from "../constants";

const checkValidAttachmentField = (field, ploomesField) => {
  if (!field || !ploomesField) return [];

  
  if (ploomesField.Field.TypeId === 19) {
    const fileName = field?.Attachment?.FileName;

    const invalidAttachments = INVALID_ATTACHMENTS.filter(invalidAttachment => {
      return invalidAttachment === /.[^.]+$/.exec(fileName)[0]
    });
    
    return invalidAttachments;
  }

  return [];
}

export default checkValidAttachmentField;