import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import './inputColorPicker.css';

export const InputColorPicker = props => {
  const {
    setValue,
    field,
    index,
  } = props;

  const [color, setColor] = useState({ hex: '#ffffff' });
  const [showPicker, setShowPicker] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(color.hex, index, field.ObjectKey, field.Required);
  }, [color])


  const handleInputChange = (color) => {
    setColor(color);
  }

  const handleTogglePicker = (e) => {
    e.preventDefault();
    setShowPicker(!showPicker);
  }

  return (
    <div className='form-content__color-picker'>
      <button
        className={`form-content__color-picker__button ${showPicker ? '--active' : ''}`}
        onClick={handleTogglePicker}
      >
        <div className='form-content__color-picker__button__inside' style={{
          backgroundColor: color.hex,
        }} />
      </button>
      {showPicker &&
        <>
          <SketchPicker
            ref={inputRef}
            className='form-content__color-picker-float'
            color={color}
            onChangeComplete={handleInputChange}
            onChange={handleInputChange}
            disableAlpha={true}
          />
          <div className='form-content__color-backdrop' onClick={handleTogglePicker} />
        </>
      }
    </div>
  );
}

InputColorPicker.propTypes = {
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
