export const unshift = ((view_value, setInputValue) => {
  if (!view_value) return null;
  const only_numbers = view_value.replace(/\D/g, '');
  let numbers_with_shifted_decimals;

  if (only_numbers.length > 2) {
    numbers_with_shifted_decimals = only_numbers.substring(0, only_numbers.length - 2) + '.' + only_numbers.substring(only_numbers.length - 2);
  } else {
    numbers_with_shifted_decimals = '0.' + (only_numbers.length === 1 ? '0' : '') + only_numbers;
  }

  let float_number = parseFloat(numbers_with_shifted_decimals);
  if (isNaN(float_number)) {
    float_number = 0;
    setInputValue('0.00');
  } else {
    setInputValue(float_number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
  }

  return float_number;
});