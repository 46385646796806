import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader'
import './index.css';
import './translation/in18/doc';
import PloomesExternalForm from './PloomesExternalForm';
import { RecoilRoot } from 'recoil';

WebFont.load({
  google: {
      families: ["Open Sans:400,600,700", "Source Sans Pro:400,600,700"]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <PloomesExternalForm />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

