/**
 * @type {{ translation: ITranslationObject }}
 */
const es = {
  translation: {
    FIELDS: {
      REQUIRED: 'campo obligatorio',
      REQUIRED_EMPTY: 'Campo obligatorio no rellenado',
      INVALID_INPUT: 'El valor ingresado no es válido',
      PHONE_WITHOUT_MASK: 'Sin mascarilla',
      PHONE_TYPE_COMERCIAL: 'Comercial',
      PHONE_TYPE_CELLPHONE: 'Teléfono móvil',
      PHONE_TYPE_RESIDENTIAL: 'Residencial',
      PHONE_TYPE_FAX: 'Fax',
      PHONE_TYPE_OTHERS: 'Otros',
      INPUT_WITHOUT_OPTIONS: 'No hay opciones que coincidan con tu búsqueda',
      FILE_MAX_SIZE_ALLOWED: 'Tamaño máximo permitido del archivo: 5MB',
      FILE_TOO_BIG_WARN: 'El archivo {{item}} más grande que 5MB',
      CHOOSE_FILE: 'Seleccionar archivo',
    },
    GENERICS: {
      TOASTER: {
        FORM_ERROR: 'Error al enviar el formulario',
        FORM_SUCCESS: 'Formulario enviado exitosamente',
      },
      EXTERNAL_FORM_TITLE: 'Forma externa | Powered by Ploomes',
      SEND: 'Enviar',
      HOUR: 'Hora',
      FORM_NOT_FOUND: 'No se ha encontrado un formulario'
    },
  },
};

export default es;
