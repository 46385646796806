import React from 'react';
import { InputColorPicker } from '../components/InputTypes/InputColorPicker/InputColorPicker';
import { InputDateTime } from '../components/InputTypes/InputDateTime/InputDateTime';
import { InputFormattedPhone } from '../components/InputTypes/InputFormattedPhone/InputFormattedPhone';
import { InputFormattedText } from '../components/InputTypes/InputFormattedText/InputFormattedText';
import { InputSelect } from '../components/InputTypes/InputSelect/InputSelect';
import { InputText } from '../components/InputTypes/InputText/InputText';
import { InputTextArea } from '../components/InputTypes/InputTextArea/InputTextArea';
import { InputUpload } from '../components/InputTypes/InputUpload/InputUpload';
import { getLocaleSeparator } from './locale';
import { validateCnpj } from './validateCnpj';
import { validateCpf } from './validateCpf';

export const getInputType = (value, setValue, field, index, phoneTypes) => {
  const isLatitudeOrLongitude = field.Field.Key.includes('_latitude') || field.Field.Key.includes('_longitude')
  const localeSeparator = isLatitudeOrLongitude ? '.' : getLocaleSeparator();

  if (field) {
    switch (field.Field.TypeId) {
      case 1: //texto simples
      case 7: //multiplas opções
      case 17: //endereço
      case 21: //assinatura
      default:
        if (field.Field.SecondaryEntityId === 28) {
          return (<InputFormattedPhone
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            phoneTypes={phoneTypes}
          />)
        }
        return (
          <InputText
            value={value}
            setValue={setValue}
            field={field}
            index={index}
          />
        )
      case 2: //texto multilinha
        return (
          <InputTextArea
            value={value}
            setValue={setValue}
            field={field}
            index={index}
          />
        )
      case 4: //numérico
        return (
          <InputFormattedText
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            decimalScale={0}
            allowedDecimalSeparators={[]}
            decimalSeparator={null}
          />
        )
      case 5: //moeda
        return (
          <InputText
            value={value}
            setValue={setValue}
            field={field}
            index={index}
          />
        )
      case 6: //decimal
        return (
          <InputFormattedText
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            allowedDecimalSeparators={[',','.']}
            decimalSeparator={localeSeparator}
          />
        )

      case 8: //data
        return (
          <InputDateTime
            key={index}
            setValue={setValue}
            field={field}
            index={index}
            type="date"
          />
        )
      case 9: //horário
      return (
        <InputDateTime
          key={index}
          setValue={setValue}
          field={field}
          index={index}
          type="time"
        />
      )
      case 10: //checkbox
      return (
        <InputSelect
          key={index}
          index={index}
          field={field}
          setValue={setValue}
          fieldOptions={
            [
              { value: 'Sim', label: 'Sim' },
              { value: 'Não', label: 'Não' },
            ]
          }
        />
      )
      case 11: // cpf
        return (
          <InputFormattedText
            key={index}
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            format="###.###.###-##"
            validation={validateCpf}
          />
        )
      case 12: // cnpj
        return (
          <InputFormattedText
            key={index}
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            format="##.###.###/####-##"
            validation={validateCnpj}
          />
        )
      case 13: // porcentagem
        return (
          <InputFormattedText
            key={index}
            value={value}
            setValue={setValue}
            field={field}
            index={index}
            allowedDecimalSeparators={[',','.']}
            decimalSeparator={localeSeparator}
            suffix='%'
          />
        )
      case 18: // imagem (upload)
        return (
          <InputUpload
            accept='image/png, image/jpeg, image/gif'
            name={field.Label}
            setValue={setValue}
            field={field}
            index={index}
          />
        )
      case 19: // anexo (upload)
        return (
          <InputUpload
            accept='*'
            name={field.Label}
            setValue={setValue}
            field={field}
            index={index}
          />
        )
      case 20: // cor
        return (
          <InputColorPicker
            setValue={setValue}
            field={field}
            index={index}
          />
        )
    }
  }

}