import React from 'react';
import './toaster.css';

export const Toaster = props => {
  const {
    message,
    type,
    show,
  } = props;

  const colors = {
    SUCCESS: '--success',
    ERROR: '--error',
  }

  return (
    <div className={`toaster ${show ? '--active' : ''} ${colors[type]}`}>
      <p className='toaster__message'>{message}</p>
    </div>
  );
}