import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, es, ptBR, ptPT } from "./in18";

i18n
.use(initReactI18next)
.init({
  resources: { en, es, ptBR, ptPT },
  lng: "ptBR",
  fallbackLng: "ptBR",

  interpolation: {
    escapeValue: false
  }
});