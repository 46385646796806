import { checkEmptyFieldValue, checkValidAttachmentField, formatField } from "../../../utils";

function formatPayload(props = {}) {
	const {
		payload = [],
		ordinatedFields = []
	} = props;
	
  const response = {
		data: {},
		hasErrorOnForm: false
	};
	for(const field of payload) {
		const newField = { ...field };
		const objKey = Object.keys(newField)[0];
		const ploomesField = ordinatedFields.find(ordinatedField => ordinatedField.ObjectKey === objKey);
		newField[objKey] = formatField(ploomesField, newField[objKey])
		const emptyField = checkEmptyFieldValue(newField[objKey]);
		const isRequired = emptyField && newField.required;
		const isInvalid = newField.hasOwnProperty('isValid') && !newField.isValid;
		const invalidAttachments = checkValidAttachmentField(newField[objKey], ploomesField);
		
		const hasErrorInField = isRequired || isInvalid || invalidAttachments?.length;

		if (hasErrorInField) {
			response.hasErrorOnForm = true;
			return response;
		}

		delete newField.required;
		delete newField.isValid;
		if (newField[objKey] === '') newField[objKey] = null;
		response.data = { ...response.data, ...newField }
	}

	return response;
}

export default formatPayload;
