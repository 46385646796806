function getLanguage(language) {
  switch(language) {
    case 1:
    case 'pt-BR':
    default:
      return 'ptBR'
    case 2:
    case 'en-US':
      return 'en'
    case 3:
    case 'pt':
      return 'ptPT'
    case 4:
    case 'es':
      return 'es'
  };
};

export default getLanguage;