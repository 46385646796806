import { useTranslation } from 'react-i18next';

function useTranslator() {
  const { t, i18n } = useTranslation();
  /**
   * @param {ITranslation} key
   */
  const setTranslation = (key, options = {})=> {
    return t(key, options);
  } 

  return {
    Translation: setTranslation,
    configTranslation: i18n
  };
};

export default useTranslator;